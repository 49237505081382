import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { ContentHeadlineVariant } from "scmp-app/components/content/content-headline";
import { MagazinesStyleTagsMap } from "scmp-app/components/content/content-headline/content-headline-tag/variants/magazines-style/const";
import type { contentHeadlineTagContent$key } from "scmp-app/queries/__generated__/contentHeadlineTagContent.graphql";

import { useDetermineHeadlineTag } from "./hooks";
import { Description, Flag, FlagDivider, Heading, StyledTooltip } from "./styles";

export type Props = {
  hideTooltip?: boolean;
  reference: contentHeadlineTagContent$key;
  useSectionTags?: boolean;
  variant?: ContentHeadlineVariant;
};

export const ContentHeadlineTag: FunctionComponent<Props> = ({
  hideTooltip,
  reference,
  useSectionTags = false,
  variant,
}) => {
  const content = useFragment(
    graphql`
      fragment contentHeadlineTagContent on Content {
        ...hooksContentHeadlineTagContent
      }
    `,
    reference,
  );
  const { color, description, entityId, name } =
    useDetermineHeadlineTag(content, useSectionTags) ?? {};

  if (!name) return null;

  const renderFlag = () => (
    <Flag $variant={variant} color={color}>
      {variant === "magazines-style" ? (
        <>
          {entityId ? MagazinesStyleTagsMap[entityId] : name}
          <FlagDivider> / </FlagDivider>
        </>
      ) : (
        <>
          {name}
          <FlagDivider> | </FlagDivider>
        </>
      )}
    </Flag>
  );

  const renderName = () => {
    if (!notEmpty(name)) return null;
    return <Heading>{name}</Heading>;
  };

  const renderDescription = () => {
    if (!notEmpty(description)) return null;
    return <Description>{description}</Description>;
  };

  if (hideTooltip === true || !notEmpty(description)) return renderFlag();

  return (
    <StyledTooltip
      placement="bottom-start"
      title={
        <>
          {renderName()}
          {renderDescription()}
        </>
      }
      arrow
    >
      {renderFlag()}
    </StyledTooltip>
  );
};

ContentHeadlineTag.displayName = "ContentHeadlineTag";

/* eslint-disable detect-bad-words/in-code */
// CSpell:disable
export const author = {
  advertisingPartner: {
    entityId: "269949",
    entityUuid: "31a94a9f-c14e-43de-a68b-01d36180cc5a",
    name: "Advertising partner",
    routeType: "Author",
    urlAlias: "/author/advertising-partner",
  },
  alexLo: {
    entityId: "721",
    entityUuid: "653ca286-cbd7-4b9d-8bd3-e62b24784d40",
    name: "Alex Lo",
    routeType: "Author",
    urlAlias: "/author/alex-lo",
  },
  cliffBundle: {
    entityId: "3265",
    entityUuid: "61c810a5-9347-4013-8f74-ecd56ec34b37",
    name: "Cliff Buddle",
    routeType: "Author",
    urlAlias: "/author/cliff-buddle",
  },
  luisaTam: {
    entityId: "875",
    entityUuid: "93c34d13-a3f8-4aae-9aea-6865dbe5fed7",
    name: "Luisa Tam",
    routeType: "Author",
    urlAlias: "/author/luisa-tam",
  },
  robertDelaney: {
    entityId: "324171",
    entityUuid: "96414dff-5500-4205-8d9e-cd7406fee5c9",
    name: "Robert Delaney",
    routeType: "Author",
    urlAlias: "/author/robert-delaney-us-bureau-chief",
  },
  scmpEditorial: {
    entityId: "269333",
    entityUuid: "0c207279-a4b6-4b24-bcc4-fb9fde64acd3",
    name: "SCMP Editorial",
    routeType: "Author",
    urlAlias: "/author/scmp-editorial",
  },
  scmpPR: {
    entityId: "512675",
    entityUuid: "fcaebe76-983d-45e0-9ad6-3178c3c6457a",
    name: "SCMP PR",
    routeType: "Author",
    urlAlias: "/author/scmp-pr",
  },
  tammyTam: {
    entityId: "148585",
    entityUuid: "b54d94f1-2e61-4333-b7fd-4668de697747",
    name: "Tammy Tam",
    routeType: "Author",
    urlAlias: "/author/tammy-tam",
  },
  wangXiangwei: {
    entityId: "4942",
    entityUuid: "84528db3-b887-4a54-867b-55d5fd889d0f",
    name: "Wang Xiangwei",
    routeType: "Author",
    urlAlias: "/author/wang-xiangwei",
  },
  yondenLhatoo: {
    entityId: "318552",
    entityUuid: "6e43af1d-ae00-4ead-b6d2-da9d93cd6a3a",
    name: "Yonden Lhatoo",
    routeType: "Author",
    urlAlias: "/author/yonden-lhatoo",
  },
} as const;
/* CSpell:enable */

export const authorTypes = {
  Blogger: "blogger",
  Columnist: "columnist",
  ScmpColumnist: "scmp_columnist",
} as const;
